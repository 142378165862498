@import '@/global-components/brand-assets/bw-styles.scss';

.printer-schedule-list {
  padding: $basic-increment *2;
  .schedule-item.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .no-schedule {
    display: flex;
    justify-content: center;
    opacity: 0.3;
  }
}