.print-requests {
  td {
    outline: yellow!important;
  }
  table tbody tr:first-child td:first-child {
    border-top-left-radius: 14px;
  }
  table tbody tr:first-child td:last-child {
    border-top-right-radius: 14px;
  }
  table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 14px;
  }
  table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 14px;
  }
  table tbody tr {
    border: none;
  }
  table tbody tr:hover {
    background-color: none !important;
    td {
      background-color: none !important;
    }
  }
  td {
    transition: background-color ease-out 50ms;
    background-color: white;
    border-top: 1px solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  table tbody tr:last-child td {
    border-bottom: none !important;
  }
}